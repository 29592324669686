
import { Storage } from './../Core/Storage';
export class GtmProvider {

    static getGtmProduct(product) {
        return {
            'id': product.code,
            'name': product.name,
            'price': product.priceTable.totalPriceExclVat, // bez DPH a se slevami 
            'fullPrice': product.priceTable.basePriceInclVat, // s DPH a bez slev 
            'tax': product.priceTable.totalVat,
            'brand': '',
            'category': '',
            'categoryId': '',
            'variant': '',
            'variantId': '',
            'rating': '',
            'labels': product.labels,
            'available': product.availability,
            'coupon': ''
        };
    }

    //1.20
    static sendMetaData(currencyCode, locale, title) {
        dataLayer.push({
            'code': {
                'v': '2.1'
            },
            'page': {
                'currencyCode': currencyCode,
                'locale': locale,
                'title': title,
                'fullPath': null
            }

        });
    }

    //1.21
    static sendSessionData(ipAddress) {
        dataLayer.push({
            'session': {
                'ip': ipAddress,
                'machine': null,
                'application': null
            }
        });
    }

    //1.30
    static sendPage() {
        dataLayer.push({ 'event': 'page' });
    }

    //1.40
    static sendUser(user, transactioncount, transactionsum, lasttransaction) {
        dataLayer.push({
            'user': {
                'id': user.id,
                'g': 'f',
                'ts': 'customer',
                'tc': transactioncount, // včetně aktuální transakce 
                'tv': transactionsum, // včetně aktuální transakce 
                'tl': lasttransaction,
                'email': user.email,
                'postalCode': user.zip,  // PSČ 
                'countryCode': user.countryCode, // 2-místný kód země dle ISO 3166-1 alpha-2 
                'ut': user.city, // Město / vesnice 
                'createdAt': user.registered // ve tvaru rrrr-mm 
            }

        });
    }

    //1.50
    static sendCart(cart) {
        var products = [];
        var idx = 0;
        if (cart) {
            cart.cartedProducts.forEach((cartItem) => {
                idx++;
                var product = GtmProvider.getGtmProduct(cartItem.product);
                product.list = 'Cart';
                product.position = idx;
                product.quantity = cartItem.count;
                product.coupon = null;
                products.push(product);
            });

            dataLayer.push({
                'cart': {
                    'id': '', // ID košíku 
                    'products': products
                }
            });
        }
    }


    static sendCartToAdForm(cart) {

        if (window.adForm) {
            if (cart) {
                var products = cart.cartedProducts.map((cartItem) => {
                    return {
                        productid: cartItem.product.code,
                        step: 2
                    };
                });


                window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
                window._adftrack.push({
                    HttpHost: 'track.adform.net',
                    pm: 3221257,
                    pagename: encodeURIComponent('Basket'),
                    divider: encodeURIComponent('|'),
                    order: {
                        itms: products
                    }
                });
                (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();

            }
        }

    }     

    static sendPageToAdForm() {

        if (window.adForm) {
           
            window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
            window._adftrack.push({
                HttpHost: 'track.adform.net',
                pm: 3221257
            });
            (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();

            }

   
    }


	static getCountryCode2Digit(code) {
    switch (code) {
        case "CZE":
            return "CZ";
        case "UKR":
            return "UA";
        case "SVK":
            return "SK";
        case "HUN":
            return "HU";
        default:
            return "CZ";
    }
}
	//1.60
	static sendorder(order) {
    var address = order.invoiceContact;
    if (address) {
        //send uder
        dataLayer.push({
            'user': {
                'fname': address.firstName, // POVINNÉ
                'lname': address.lastName, // POVINNÉ
                'email': address.email, // PREFEROVANÉ								
                'postalCode': address.zip, // PSČ ➝ POVINNÉ
                'countryCode': GtmProvider.getCountryCode2Digit(address.country.code) // 2-místný kód země dle ISO 3166-1 alpha-2 ➝ POVINNÉ
            }
        });
    }
    dataLayer.push({
        'order': {
            'id': order.invoiceCode,
            'revenue': order.partialPriceTable.cart.totalPriceExclVat, // cena jen za zboží bez DPH (bez dopravy, platby…), se slevami 
            'productRevenue': order.partialPriceTable.cart.basePriceExclVat, // cena jen za zboží bez DPH, nejsou započítány slevy 
            'discount': order.partialPriceTable.cart.discountPriceExclVat, // celkový součet slev 
            'tax': order.priceTable.totalVat, // samotné DPH za zboží, platbu, dopravu 
            'shipping': order.partialPriceTable.shipping.totalPriceExclVat, // cena dopravy a platby (bez DPH) 
            'totalValue': order.priceTable.totalPriceInclVat, // celkova cena za zboží s DPH včetně dopravoy, platby…   
            'deliveryType': order.shipping.name,
            'paymentType': order.payment.name,
            'coupon': null,  // slevovy kupon na celou objednávku, string|null 
            'processingType': null
        }
    });
    //adform
    if (window.adForm) {
        window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
        window._adftrack.push({
            HttpHost: 'track.adform.net',
            pm: 3221257,
            pagename: encodeURIComponent('Conversion page'),
            divider: encodeURIComponent('|'),
            order: {
                itms:
                    order.orderedProducts.map(cartedProduct => {
                        return {
                            productid: cartedProduct.product.code,
                            step: 3
                        }
                    })
            }
        });
        (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();
    }


    //sklik
    var conversionConf = {
        orderId: order.invoiceCode,  // Číslo objednávky
        value: order.priceTable.totalPriceExclVat, // Hodnota objednávky
        consent: 1, // Souhlas od návštěvníka na odeslání konverzního hitu
        zboziId: "89856", // ID provozovny na Zboží
        zboziType: "limited", // Varianta/verze kódu Zboží
    };
    // Ujistěte se, že metoda existuje, předtím než ji zavoláte
    if (window.rc && window.rc.conversionHit) {
        window.rc.conversionHit(conversionConf);
    }

    window.Targito = window.Targito || [];
    Targito.push('event', 'order', {
        'order_id': order.invoiceCode,
        'total': order.priceTable.totalPriceExclVat,
        'total_vat': order.priceTable.totalPriceInclVat,
        'products': order.orderedProducts.map(cartedProduct => {
            return {
                'item_id': cartedProduct.product.code,
                'item_price': cartedProduct.priceTable.totalPriceExclVat,
                'item_price_vat': cartedProduct.priceTable.totalPriceInclVat,
                'item_quantity': cartedProduct.count
            }
        }),
        'attributes': {
        }
    });
}

	//1.70
	static sendBreadCrumb(breadcrumb) {
    dataLayer.push({ 'page.trail': breadcrumb });
}

	//2.05
	static sendHome() {
    dataLayer.push({
        'page': {
            'type': 'home',
            'kind': null
        }
    });
}
	//2.10
	static sendProductDetail(product) {
    dataLayer.push({
        'page': {
            'type': 'detail',
            'kind': 'product',
            'detail': {
                'products': [
                    product
                ]
            }
        }
    });

    window.Targito = window.Targito || [];
    Targito.push('event', 'product_view', { 'id': product.id });
}
	//2.15
	static sendcheckoutPage(step) {
    dataLayer.push({
        'page': {
            'type': 'checkout',
            'kind': 'checkout',
            'checkout': { 'step': step } // číslo kroku 
        }
    });
}
	//2.20
	static sendPurchase(currency) {
    dataLayer.push({
        'page': {
            'type': 'purchase',
            'kind': 'purchase',
            'currencyCode': currency
        }
    });
}
	//2.25
	static sendSearch(term, itemscount) {
    dataLayer.push({
        'page': {
            'type': 'searchresults',
            'kind': 'searchresults',
            'searchresults': {
                'type': 'fulltext', // Typ vyhledávání 
                'term': term, // Hledaný řetězec 
                'resultCount': itemscount // Počet výsledků 
            }
        }
    });
}
	//2.30
	static sendError(code, message) {
    dataLayer.push({
        'page': {
            'type': 'error',
            'kind': 'error',
            'error': {
                'type': code, // Kód chyby 
                'message': message // Popis chyby 
            }
        }
    });
}

	//2.50
	static sendPriceList(pageIdx, sorting, filter) {
    dataLayer.push({
        'page': {
            'type': 'list',
            'kind': 'list',
            'list': {
                'pageNumber': pageIdx,
                'filters': filter
            }, // pouze je-li jednoduché 
            'sorting': sorting
        }
    })
}

	static sendAccount(subpage) {
    dataLayer.push({
        'page': {
            'type': (subpage) ? 'account' + "|" + subpage : 'account',
            'kind': 'account'
        }
    });
}
	//3.10
	static sendProductList(products) {
    dataLayer.push({
        'impression': {
            'products': products
        }
    });
}

	//3.20
	static onProductClick(product) {
    products.push(product);
    dataLayer.push({
        'event': 'action.click.products',
        'action': {
            'click': {
                'products': [
                    product
                ]
            }
        }
    });
}

	//3.30
	static onAddCartAction(product, list, position, quantity) {
    var item = GtmProvider.getGtmProduct(product);
    item.quantity = quantity;
    item.position = position;
    item.list = list;
    dataLayer.push({
        'event': 'action.add.products', // action.add|action.remove podle akce 
        'action': {
            'add': { // add|remove podle zvolené akce 
                'products': [item]
            }
        }
    });
}

	//3.30
	static onAddProductCartAction(item, quantity) {
    var items = Storage.instance().cachedCart.cartedProducts;
    item.quantity = quantity;
    dataLayer.push({
        'event': 'action.add.products', // action.add|action.remove podle akce
        'action': {
            'add': { // add|remove podle zvolené akce
                'products': [item]
            }
        }
    });

}
	//3.30
	static onRemoveCartAction(product, quantity) {
    var item = GtmProvider.getGtmProduct(product);
    item.quantity = quantity;
    dataLayer.push({
        'event': 'action.remove.products', // action.add|action.remove podle akce 
        'action': {
            'remove': { // add|remove podle zvolené akce 
                'products': [item]
            }
        }
    });
}


	static onCartUpdateAction(data) {
    window.Targito = window.Targito || [];
    Targito.push('property', 'cart', {
        'url': '/home/cart',
        'total': data.priceTable.totalPriceExclVat,
        'total_vat': data.priceTable.totalPriceInclVat,
        'products': data.cartedProducts.map(cartedProduct => {
            return {
                'item_id': cartedProduct.product.code,
                'item_price': cartedProduct.priceTable.totalPriceExclVat,
                'item_price_vat': cartedProduct.priceTable.totalPriceInclVat,
                'item_quantity': cartedProduct.count
            }
        }),
        'attributes': {
        }
    });
}

	//4.10
	static onRegistrationNews(form) {
    dataLayer.push({
        'event': 'action.optin.user',
        'action': {
            'optin': {
                'element': form // identifikace formuláře/okna, kde provedl registraci 
            }
        }
    });
}

	static onLogin(loginType) {
    dataLayer.push({
        'event': 'action.login.user',
        'action': {
            'login': {
                'type': loginType // způsob přihlášení login|facebook|ldap 
            }
        }
    });
}

	static onRegister(loginType) {
    dataLayer.push({
        'event': 'action.create.user',
        'action': {
            'create': {
                'type': loginType // způsob přihlášení login|facebook|ldap 
            }
        }
    });
}

}
