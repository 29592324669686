
import { Storage } from './../Core/Storage';
export class RtbHouseProvider {

    static anonymiseEmail(email) {
        var arr = email.split("@");
        var str = arr[0];
        return str[0] + "*".repeat(str.length - 2) + str.slice(-1) + "@" + arr[1];
    }

    static getAnonymizedUserEmail(user) {
        return user ? this.anonymiseEmail(user.email) : "unknown";
    }

    static pushEvent(event, user) {
        window.rtbhEvents = window.rtbhEvents || []; 
        (window.rtbhEvents).push(event,
            {
                eventType: 'uid',
                id: this.getAnonymizedUserEmail(user)
            });
    }

    static sendHome(user) {
        this.pushEvent({ eventType: 'home' }, user);
    }

    static loadCategory(categoryId, user) {
        this.pushEvent({ eventType: 'category', categoryId: String(categoryId) }, user);
    }



    static loadSale(user) {
        this.pushEvent({ eventType: 'sales'}, user);
    }


    static loadProduct(product, user) {
        this.pushEvent({ eventType: 'offer', offerId: product.id }, user);
    }


    static loadSearch(ids, user) {
        var idsToSend = Array.isArray(ids) ? ids.slice(0, 5).map(String) : [];
        this.pushEvent({ eventType: 'listing', offerIds: idsToSend }, user);
    }

    static onAddProductToCart(product, user) {
        this.pushEvent({ eventType: 'basketadd', offerId: product.code }, user);
    }

    static onAddProductCartAction(item, quantity) {
    }


    static loadCart(cart, user) {
        var products = [];
        if (cart && cart.cartedProducts) {
            cart.cartedProducts.forEach((cartItem) => {
                products.push(cartItem.product.code);
            });

            this.pushEvent({ eventType: 'basketstatus', offerIds: products }, user);
        }
    }

    static sendCart(user) {
        this.pushEvent({ eventType: 'startorder' }, user);
    }



    static sendOrder(order, user) {
        var products = order.orderedProducts.map(orderedProduct => {
            return orderedProduct.product.code
        });

        this.pushEvent({eventType: 'conversion', conversionClass: 'order',
            conversionSubClass: 'purchase', conversionId: order.invoiceCode,
            offerIds: products, conversionValue: String(order.priceTable.totalPriceInclVat),
            conversionCurrency: order.currency.code}, user);
    }

}
